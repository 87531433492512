import { inject, Injector, signal } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormControlOptions, FormGroup, ValidatorFn } from '@angular/forms';
import { KDateFormat, KDateNow, KDateSub } from '@kiwid-app/kiwid-date-typescript';
import { TranslateService } from '@ngx-translate/core';
import { DATE_API } from 'app/core/const/date.const';
import { EFieldLaboratoryParameter, EParameterLaboratoryParameter, EStatusLaboratoryParameter } from 'app/core/enums/laboratory-parameter.enum';
import { CountrySettingHelper } from 'app/core/helpers/country-setting.helper';
import {
    dateValidForm,
    emailForm,
    isDocumentForm,
    maxDateForm,
    minDateForm,
    requiredForm,
} from 'app/core/helpers/form-validation/form-validation.helper';
import { Util } from 'app/core/helpers/util';
import { ICustomerCustomParameter, IRegisterLaboratoryParameter } from 'app/core/models/customer/customer';
import { ILaboratoryParameter } from 'app/core/models/laboratory/laboratory-parameter';
import { FuseTranslationLoaderService } from 'app/core/translate/translation-loader.service';
import { first } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';

export const translateFormDynamic = signal<any>({});

export const populateTranslateFormDynamic = (fuseTranslationLoaderService: FuseTranslationLoaderService, translateService: TranslateService) => {
    fuseTranslationLoaderService.loadTranslations(portuguese, english, spanish);
    translateService
        .get('FORM_DYNAMIC')
        .pipe(first())
        .subscribe((response: any) => {
            translateFormDynamic.set(response);
        });
};

export function translationFormDynamic(
    key: string,
    params?: {
        [key: string]: string;
    },
    type?: 'one' | 'other' | 'zero',
) {
    return Util.translateKey(translateFormDynamic(), key, params, type);
}

type IAddControlToForm = {
    formGroup: FormGroup;
    parameter: any;
    injector?: Injector;
};

type IFormatParametersToApi = {
    customerParameters: { [key: string]: string };
    laboratoryParameters: ILaboratoryParameter[];
};

export class FormDynamicHelper {
    static addControlToForm(data: IAddControlToForm): void {
        const { formGroup, parameter, injector } = data;

        const formBuilder = data?.injector?.get(FormBuilder) ?? inject(FormBuilder);
        const countrySettingHelper = data?.injector?.get(CountrySettingHelper) ?? inject(CountrySettingHelper);
        const maxBirthdate = signal(KDateSub(KDateNow(), 'years', environment.maxYearBirthdate));
        const minBirthdate = signal(KDateSub(KDateNow(), 'years', environment.minYearBirthdate));

        if (parameter.field === EFieldLaboratoryParameter.CUSTOM) {
            let disabled = parameter.status === EStatusLaboratoryParameter.INACTIVE;
            (formGroup.get('parameters') as FormGroup).addControl(
                parameter.id,
                formBuilder.control({ value: null, disabled }, parameter.isRequired ? [requiredForm()] : []),
                { emitEvent: false },
            );
            return;
        }

        let fieldName: string | null = null;
        let fieldValue = null;
        let validators: ValidatorFn | ValidatorFn[] | FormControlOptions | null =
            parameter.isRequired && parameter.status !== EStatusLaboratoryParameter.INACTIVE ? [requiredForm()] : [];

        switch (parameter.field) {
            case EFieldLaboratoryParameter.NAME: {
                fieldName = 'name';
                break;
            }
            case EFieldLaboratoryParameter.EMAIL: {
                fieldName = 'email';
                validators.push(emailForm());
                break;
            }
            case EFieldLaboratoryParameter.BIRTHDATE: {
                fieldName = 'birthdate';
                validators.push(dateValidForm({ format: translationFormDynamic('DATE_FORMAT') }));
                validators.push(
                    minDateForm({
                        format: translationFormDynamic('DATE_FORMAT'),
                        minDate: minBirthdate(),
                    }),
                );
                validators.push(
                    maxDateForm({
                        format: translationFormDynamic('DATE_FORMAT'),
                        maxDate: maxBirthdate(),
                    }),
                );
                break;
            }
            case EFieldLaboratoryParameter.LEGAL_NAME: {
                fieldName = 'legalName';
                break;
            }
            case EFieldLaboratoryParameter.RESPONSIBLE: {
                fieldName = 'responsible';
                break;
            }
            case EFieldLaboratoryParameter.GENDER: {
                fieldName = 'gender';
                break;
            }
            case EFieldLaboratoryParameter.DOCUMENT: {
                fieldName = 'document';
                validators.push(isDocumentForm(countrySettingHelper, { type: 'both' }));
                break;
            }
            case EFieldLaboratoryParameter.CLINIC_NAME: {
                fieldName = 'clinicName';
                break;
            }
            case EFieldLaboratoryParameter.MEMBER_SINCE: {
                fieldName = 'memberSince';
                fieldValue = KDateFormat(KDateNow(), translationFormDynamic('DATE_FORMAT'));
                validators.push(dateValidForm({ format: translationFormDynamic('DATE_FORMAT') }));
                validators.push(maxDateForm({ format: translationFormDynamic('DATE_FORMAT'), maxDate: KDateNow() }));

                formGroup.addControl('isNewCustomer', formBuilder.control(true, []), { emitEvent: false });

                break;
            }
            case EFieldLaboratoryParameter.CRO: {
                fieldName = 'croState';
                formGroup.addControl('croNumber', formBuilder.control(null, validators), { emitEvent: false });

                break;
            }

            default:
                break;
        }

        if (!!fieldName) {
            formGroup.addControl(fieldName, formBuilder.control(fieldValue, validators), { emitEvent: false });
        }
    }

    static setFormValue(
        formGroup: FormGroup,
        parameters: ILaboratoryParameter[],
        values: any,
        customFields: ICustomerCustomParameter[] | null,
    ): void {
        Object.entries(values).forEach(([key, value]) => {
            let fieldValue = value;
            const parameter = parameters.find((parameter) => FormDynamicHelper.getFormControlName(parameter) === key);
            if (!!parameter) {
                if (parameter.parameter === EParameterLaboratoryParameter.INPUT_DATE && !!value && typeof value === 'string') {
                    fieldValue = KDateFormat(
                        {
                            value: value,
                            format: DATE_API,
                        },
                        translationFormDynamic('DATE_FORMAT'),
                    );
                }
            }
            if (!!formGroup?.get(key)) {
                if (formGroup?.get(key) instanceof FormGroup || formGroup?.get(key) instanceof FormControl) {
                    formGroup?.get(key)?.setValue(fieldValue, { emitEvent: false });
                } else if (formGroup?.get(key) instanceof FormArray && Array.isArray(fieldValue)) {
                    fieldValue.forEach((field: any) => {
                        const formGroupField = new FormGroup({});
                        Object.entries(field).forEach(([keyField, valueField]) => {
                            formGroupField.addControl(keyField, new FormControl(valueField));
                        });
                        (formGroup?.get(key) as FormArray)?.push(formGroupField);
                    });
                }
            }
        });

        if (customFields?.length > 0) {
            customFields.forEach((customField) => {
                let fieldValue = null;
                const parameter = parameters.find((parameter) => parameter.id === customField.parameterId);
                if (!!parameter) {
                    if (parameter.parameter === EParameterLaboratoryParameter.INPUT) {
                        fieldValue = customField.customerCustomParameterOptions?.at(0)?.optionStringValue;
                    } else if (parameter.parameter === EParameterLaboratoryParameter.INPUT_DATE) {
                        const customValue = customField.customerCustomParameterOptions?.at(0)?.optionStringValue;
                        fieldValue = !!customValue
                            ? KDateFormat(
                                  {
                                      value: customValue,
                                      format: DATE_API,
                                  },
                                  translationFormDynamic('DATE_FORMAT'),
                              )
                            : null;
                    } else if (parameter.parameter === EParameterLaboratoryParameter.SELECT) {
                        fieldValue = customField.customerCustomParameterOptions?.at(0)?.optionId;
                    } else if (parameter.parameter === EParameterLaboratoryParameter.SELECT_CHECKBOX) {
                        fieldValue = customField.customerCustomParameterOptions?.map((option) => option.optionId);
                    }
                }
                if (!!formGroup?.get(`parameters.${customField.parameterId}`)) {
                    formGroup?.get(`parameters.${customField.parameterId}`)?.setValue(fieldValue, { emitEvent: false });
                }
            });
        }
    }

    static getFormControlName(parameter: ILaboratoryParameter): string {
        if (parameter.field === EFieldLaboratoryParameter.CUSTOM) {
            return `parameters.${parameter.id}`;
        }

        let field = {
            [`${EFieldLaboratoryParameter.NAME}`]: 'name',
            [`${EFieldLaboratoryParameter.EMAIL}`]: 'email',
            [`${EFieldLaboratoryParameter.BIRTHDATE}`]: 'birthdate',
            [`${EFieldLaboratoryParameter.LEGAL_NAME}`]: 'legalName',
            [`${EFieldLaboratoryParameter.RESPONSIBLE}`]: 'responsible',
            [`${EFieldLaboratoryParameter.GENDER}`]: 'gender',
            [`${EFieldLaboratoryParameter.DOCUMENT}`]: 'document',
            [`${EFieldLaboratoryParameter.CLINIC_NAME}`]: 'clinicName',
            [`${EFieldLaboratoryParameter.MEMBER_SINCE}`]: 'memberSince',
            [`${EFieldLaboratoryParameter.CRO}`]: 'croNumber',
            [`${EFieldLaboratoryParameter.DOCUMENT_REGIME}`]: 'documentRegime',
        };

        return !!field[parameter.field] ? field[parameter.field] : null;
    }

    static getFormControl(formGroup: FormGroup, parameter: ILaboratoryParameter): AbstractControl | null {
        if (parameter.field === EFieldLaboratoryParameter.CUSTOM) {
            return (formGroup.get('parameters') as FormGroup).get(parameter.id);
        }

        let field = {
            [`${EFieldLaboratoryParameter.NAME}`]: () => {
                return formGroup.get('name');
            },
            [`${EFieldLaboratoryParameter.EMAIL}`]: () => {
                return formGroup.get('email');
            },
            [`${EFieldLaboratoryParameter.BIRTHDATE}`]: () => {
                return formGroup.get('birthdate');
            },
            [`${EFieldLaboratoryParameter.LEGAL_NAME}`]: () => {
                return formGroup.get('legalName');
            },
            [`${EFieldLaboratoryParameter.RESPONSIBLE}`]: () => {
                return formGroup.get('responsible');
            },
            [`${EFieldLaboratoryParameter.GENDER}`]: () => {
                return formGroup.get('gender');
            },
            [`${EFieldLaboratoryParameter.DOCUMENT}`]: () => {
                return formGroup.get('document');
            },
            [`${EFieldLaboratoryParameter.CLINIC_NAME}`]: () => {
                return formGroup.get('clinicName');
            },
            [`${EFieldLaboratoryParameter.MEMBER_SINCE}`]: () => {
                return formGroup.get('memberSince');
            },
            [`${EFieldLaboratoryParameter.CRO}`]: () => {
                return formGroup.get('croNumber');
            },
            [`${EFieldLaboratoryParameter.DOCUMENT_REGIME}`]: () => {
                return formGroup.get('documentRegime');
            },
        };

        return !!field[parameter.field] ? field[parameter.field]() : null;
    }

    static getFieldIsHidden(parameter: ILaboratoryParameter, isPersonal: boolean): boolean {
        if (
            [
                EFieldLaboratoryParameter.GENDER,
                EFieldLaboratoryParameter.BIRTHDATE,
                EFieldLaboratoryParameter.CLINIC_NAME,
            ].includes(parameter.field)
        ) {
            return !isPersonal;
        }

        if ([EFieldLaboratoryParameter.LEGAL_NAME, EFieldLaboratoryParameter.RESPONSIBLE, EFieldLaboratoryParameter.DOCUMENT_REGIME].includes(parameter.field)) {
            return isPersonal;
        }

        return false;
    }

    static formatParametersToApi(data: IFormatParametersToApi): IRegisterLaboratoryParameter[] {
        const { customerParameters, laboratoryParameters } = data;

        return Object.entries(customerParameters)?.map(([key, customerParameterValue]) => {
            const field = laboratoryParameters.find((parameter) => parameter.id === key);
            let value: string | null = null;
            let valueOptions: string[] | null = null;

            if (![EParameterLaboratoryParameter.SELECT, EParameterLaboratoryParameter.SELECT_CHECKBOX].includes(field.parameter)) {
                if (!!customerParameterValue && field.parameter === EParameterLaboratoryParameter.INPUT_DATE) {
                    value = KDateFormat(
                        {
                            format: translationFormDynamic('DATE_FORMAT'),
                            value: customerParameterValue,
                        },
                        'yyyy-MM-dd',
                    );
                } else if (!!customerParameterValue) {
                    value = customerParameterValue;
                }
            } else if (
                [EParameterLaboratoryParameter.SELECT, EParameterLaboratoryParameter.SELECT_CHECKBOX].includes(field.parameter) &&
                !!customerParameterValue
            ) {
                if (Array.isArray(customerParameterValue)) {
                    valueOptions = customerParameterValue.length > 0 ? customerParameterValue : null;
                } else if (!Array.isArray(customerParameterValue)) {
                    valueOptions = [customerParameterValue];
                }
            }

            return {
                parameterId: key,
                optionIds: [EParameterLaboratoryParameter.SELECT, EParameterLaboratoryParameter.SELECT_CHECKBOX].includes(field.parameter) &&
                !!customerParameterValue
                    ? valueOptions
                    : null,
                optionStringValue: ![EParameterLaboratoryParameter.SELECT, EParameterLaboratoryParameter.SELECT_CHECKBOX].includes(field.parameter)
                    ? value
                    : null,
            } as unknown as IRegisterLaboratoryParameter;
        }) ?? [];
    }
}
